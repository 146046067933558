


















import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PictimeCartItemData',
  components: {},
  props: {
    itemCustomizableOptions: {
      type: Array,
      default: () => ['']
    },
    productConfiguration: {
      type: Object,
      default: () => {
      }
    },
    productType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const productTitle = ref('');
    const isPaint = ref(true);
    const color = ref('');
    const conditioning = ref('');

    if (!props.productConfiguration.isBaseColor
      && !props.productConfiguration.isFactoryColor
      && !props.productConfiguration.isCustomColor) {
      productTitle.value = props.productConfiguration.name;
      isPaint.value = false;
      if (props.productType === 'RMS') {
        color.value = props.productConfiguration?.color;
      }
    } else {
      productTitle.value = props.title;
      color.value = props.productConfiguration.color;
      conditioning.value = props.productConfiguration.conditioning;
      if (props.itemCustomizableOptions.length) {
        Object.keys(props.itemCustomizableOptions).forEach((custom) => {
          if (props.itemCustomizableOptions[custom].label == 'custom_color_label' && props.itemCustomizableOptions[custom].values[0].value) {
            color.value = props.itemCustomizableOptions[custom].values[0].value;
          }
        });
      }
    }

    return {
      productTitle,
      isPaint,
      color,
      conditioning
    }
  }
});
