





































































































































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import type { WishlistItem } from '~/modules/wishlist/types/types'
import { SfImage, SfQuantitySelector, SfPrice } from '@storefront-ui/vue'
import PictimeCartItemData from '~/modules/cart/components/PictimeCartItemData'
import productGetters from '~/modules/catalog/product/getters/productGetters'
import { ProductConfiguration, ProductConfigurationItem } from '~/modules/wishlist/types/types';
import PictimeFlap from '~/components/molecules/PictimeFlap.vue';
import { cachePrice } from '~/helpers/customer/role';
import calculFlap from '~/helpers/utils/calculFlap';
import calculStock from '~/helpers/utils/calculStock';
import SvgImage from '~/components/General/SvgImage.vue';
import PictimeAddToCart from '~/components/molecules/PictimeAddToCart.vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { ProductFlapType } from '~/custom-api/api/productAttributeData/types';
import { CalcStock } from '~/modules/checkout/types';

export default defineComponent({
  name: 'ProductTable',
  computed: {
    ProductFlapType() {
      return ProductFlapType
    }
  },
  components: {
    PictimeAddToCart,
    SvgImage,
    SfQuantitySelector,
    SfImage,
    SfPrice,
    PictimeFlap,
    PictimeCartItemData,
  },
  props: {
    item: {
      type: Object as PropType<WishlistItem>,
      required: true,
    },
    disableAddToCart: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { canViewPrice } = cachePrice();
    const customerStore = useCustomerStore();

    const forceToDisableBtnAddToCart = ref(false);

    let flap: string;
    let stock: CalcStock;

    if (props.item.price_stock) {
      flap = calculFlap(true, props.item);
      stock = calculStock(props.item.price_stock.stocks?.[0], props.item.quantity);

      if (props.item.price_stock?.priceExcludingTax?.packagingRegularPrice !== 'undefined') {
        forceToDisableBtnAddToCart.value = 0 >= props.item.price_stock.priceExcludingTax.packagingRegularPrice
      }
    }

    const getProductConfiguration = (): ProductConfiguration => {
      return props.item.configurable_products_configuration?.[0]
    }

    const getItemConfiguration = (): ProductConfigurationItem => {
      const configurations = getProductConfiguration()
      return configurations?.items?.[0]
    }

    const addToCart = (): void => {
      // @ts-ignore
      emit('add-to-cart', {item: props.item, configuration: getProductConfiguration()});
    }

    return {
      canViewPrice,
      store: customerStore.getCurrentShopCode as string,
      flap,
      stock,
      forceToDisableBtnAddToCart,
      productGetters,
      addToCart,
      getProductConfiguration,
      getItemConfiguration,
    }
  },
  methods: {
    reducLabel(item: WishlistItem): string {
      switch (item.price_stock?.priceExcludingTax.priceType) {
        case 'PROMOTION':
          return 'Promotion';
        case 'SPECIAL':
          return 'Votre remise';
        default:
          return '';
      }
    },

    reducClass(item: WishlistItem): string {
      switch (item.price_stock?.priceExcludingTax.priceType) {
        case 'PROMOTION':
          return 'promo';
        case 'SPECIAL':
          return 'price';
        case 'NOUVEAU':
          return 'new';
        case 'DESTOCK':
          return 'destock';
        default:
          return '';
      }
    }
  }
});
