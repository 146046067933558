
















































































































import { SfLoader, SfLink, SfButton, SfSelect } from '@storefront-ui/vue'
import { computed, defineComponent, onMounted, ref, useRouter } from '@nuxtjs/composition-api'
import SvgImage from '~/components/General/SvgImage'
import { useWishlist } from '~/modules/wishlist/composables/useWishlist'
import ProductTable from '~/modules/wishlist/components/ProductTable'
import CreateWishlistSidebar from '~/modules/wishlist/components/CreateWishlistSidebar.vue';
import wishlistGetters from '~/modules/wishlist/getters/wishlistGetters'
import useCart from '~/modules/checkout/composables/useCart'
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { ProductConfiguration, WishlistItem } from '~/modules/wishlist/types/types'
import calculStock from '~/helpers/utils/calculStock'
import { CalcStock } from '~/modules/checkout/types'
import { WishlistItemUpdateInput } from '~/modules/GraphQL/types';
import { useUiState } from '~/composables';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useProductStore } from '~/modules/catalog/product/stores/product';

export default defineComponent({
  name: 'MyWishlist',
  directives: { clickOutside },
  components: {
    SfLink,
    SfLoader,
    SfButton,
    SfSelect,
    SvgImage,
    ProductTable,
    CreateWishlistSidebar,
  },
  setup() {
    const {
      getWishlists,
      loadById,
      deleteWishlist,
      removeItem,
      updateItem,
      loading: isWishlistLoading,
    } = useWishlist();

    const {
      addItem: addItemToCart,
      error: cartError,
    } = useCart();

    const productStore = useProductStore();
    const wishlistStore = useWishlistStore();

    const { toggleCreateWishlistSidebar, toggleRenameWishlistSidebar, toggleAddToCartSidebar } = useUiState();

    const router = useRouter();

    const wishlists = ref([]);
    const wishlist = ref()
    const wishlistId = ref('');
    const selectedWishlist = ref({});

    const products = computed(() => wishlistGetters.getProducts(wishlist.value));
    const totalPrices = computed(function () {
      return products.value.reduce(function (total, el) {
        const price = el?.price_stock?.priceExcludingTax?.packagingRegularPrice || 0
        return price + total;
      }, 0)
    });

    const isAddingToCart = ref(false);
    const addToCartError = computed(() => cartError.value?.addItem?.message);

    const removeItemFromWishlist = async (item: WishlistItem) => {
      await removeItem(wishlist.value.id, item.id, {});
    };

    const addWishlistItemToCart = async (data: { item: WishlistItem, configuration: ProductConfiguration }, showModal = true) => {
      const stock: CalcStock = calculStock(data.item.price_stock.stocks?.[0], data.item.quantity);

      const hasProductPrice = data.item.price_stock?.priceExcludingTax?.packagingRegularPrice !== 'undefined' &&
        data.item.price_stock.priceExcludingTax.packagingRegularPrice > 0

      if (hasProductPrice && (stock.delivery.isOk || stock.store.isOk)) {
        isAddingToCart.value = true

        const optionsConfiguration = []
        data.item?.customizable_options.map((option) =>
          optionsConfiguration.push({ id: option.id, value_string: option?.values[0].value })
        )

        // @ts-ignore
        await addItemToCart({
          product: data.item.product,
          quantity: data.item.quantity,
          selectedOptionSku: data.configuration.sku,
          pricingUnit: data.item.price_stock.pricingUnit,
          optionsConfiguration: optionsConfiguration,
          isCustom: optionsConfiguration ? true : false
        });

        if (!addToCartError.value) {
          productStore.setProductModal(
            {
              id: data.item.product.sku,
              articleCode: data.configuration.items[0].articleCode,
              color: data.configuration.items[0].color,
              conditioning: data.configuration.items[0].conditioning,
              configuration: data.configuration.items[0].color,
              image: data.item.product.thumbnail,
              name: data.item.product.name,
              quantity: data.item.quantity,
              taxe: 'HT',
              total: Math.round((data.item.price_stock.priceExcludingTax.packagingFinalPrice) * 100) / 100,
              type: data.item.configurable_products_configuration[0].productType
            }
          )

          showModal && toggleAddToCartSidebar();
          isAddingToCart.value = false;
        } else {
          // Affiche une erreur lors de la redirection panier
          isAddingToCart.value = false;
        }
      }
    };

    const addAllWishlistItemsToCart = async () => {
      isAddingToCart.value = true;

      for (const item of products.value) {
        await addWishlistItemToCart({ item, configuration: item.configurable_products_configuration?.[0] }, false)
      }

      isAddingToCart.value = false;

      if (addToCartError.value === true) {
        await router.push({ name: 'cart', query: { error: true } })
      } else {
        await router.push({ name: 'cart' })
      }
    };

    const updateWishlistItem = async (data: { wishlistItem: WishlistItem, quantity: number }) => {
      const items = wishlist.value?.items_v2?.items ?? [];

      for (const item of items) {
        if (data.wishlistItem.id === item.id) {
          const itemToUpdate: WishlistItemUpdateInput = { wishlist_item_id: item.id, quantity: data.quantity };
          await updateItem({ wishlistId: wishlist.value.id, wishlistItems: [itemToUpdate] });
          item.quantity = data.quantity;
        }
      }

      wishlist.value.items_v2.items = items;
    };

    const onChangeWishlist = async (id: string) => {
      wishlist.value = await loadById({
        id: id,
        searchParams: {
          pageSize: 999,
          currentPage: 1
        }
      });

      wishlistId.value = wishlist.value.id;
      selectedWishlist.value = wishlists.value.find(w => w.id === wishlist.value.id)
      wishlistStore.selectedWishlistId = wishlist.value.id
    }

    const onAddWishlist = () => {
      showMenu.value = false;
      toggleCreateWishlistSidebar();
    }

    const onUpdateWishlist = (e) => {
      if (selectedWishlist.value.is_default) {
        e.preventDefault();
      } else {
        showMenu.value = false;
        toggleRenameWishlistSidebar();
      }
    }

    const onDeleteWishlist = async (e) => {
      if (selectedWishlist.value.is_default) {
        e.preventDefault();
      } else {
        showMenu.value = false;
        await deleteWishlist(wishlistId.value);
        await initWishlists();
      }
    }

    const initWishlists = async () => {
      // Récupére les ID/noms des wishlist du client
      await getWishlists();
      wishlists.value = wishlistStore.wishlistsNames;
      // Affiche la wishlist par défaut
      await onChangeWishlist(wishlists.value?.[0].id)
    }

    onMounted(async () => {
      await initWishlists();
    });

    // Gestion du menu
    const showMenu = ref(false);
    const menu = ref(null);
    const menuDropdown = ref(null);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    }

    return {
      isWishlistLoading,
      wishlists,
      wishlistId,
      selectedWishlist,
      isAddingToCart,
      totalPrices,
      products,
      onChangeWishlist,
      onAddWishlist,
      onDeleteWishlist,
      onUpdateWishlist,
      removeItemFromWishlist,
      updateWishlistItem,
      addWishlistItemToCart,
      addAllWishlistItemsToCart,
      showMenu,
      toggleMenu,
      menu,
      menuDropdown,
      toggleCreateWishlistSidebar,
      toggleRenameWishlistSidebar
    };
  },
});
